import dailyMail from "../../assets/images/partnersLogos/dailyMail2.svg"
import yahoo from "../../assets/images/partnersLogos/yahoo.svg"
import techCrunch from "../../assets/images/partnersLogos/techCrunch.svg"
import forbes from "../../assets/images/partnersLogos/forbes.svg"
import bbc from "../../assets/images/partnersLogos/bbc.svg"
import mit from "../../assets/images/partnersLogos/MIT-technology-review-logo.svg"
import techTimes from "../../assets/images/partnersLogos/techTimes.svg"
import fattoQuotidiano from "../../assets/images/partnersLogos/fattoQuotidiano2.svg"
import medium from "../../assets/images/partnersLogos/medium.svg"
import syfy from "../../assets/images/partnersLogos/syfy.svg"
import theScienceTimes from "../../assets/images/partnersLogos/theScienceTimes2.svg"
import miningCom from "../../assets/images/partnersLogos/miningCom.svg"
import popularMachines from "../../assets/images/partnersLogos/popularMachines.svg"
import fabcrosslogo from "../../assets/images/partnersLogos/fabcrosslogo2.svg"
import telegraph from "../../assets/images/partnersLogos/telegraph2.svg"
import ouestFrance from "../../assets/images/partnersLogos/ouestFrance.svg"
import nvidia from "../../assets/images/partnersLogos/nvidia.svg"
import cern from "../../assets/images/partnersLogos/cern.svg"
import epfl from "../../assets/images/partnersLogos/epfl.svg"
import orano from "../../assets/images/partnersLogos/orano.svg"
import microcity from "../../assets/images/partnersLogos/microcity.svg"
import aws from "../../assets/images/partnersLogos/aws.svg"
import climatePledge from "../../assets/images/partnersLogos/climatePledge.svg"
import lockheedMartin from "../../assets/images/partnersLogos/lockheedMartin.svg"
import cambridge from "../../assets/images/partnersLogos/cambridge.svg"
import ndbmoney from "../../assets/images/partnersLogos/ndbmoney.svg"
import watt from "../../assets/images/partnersLogos/watt.svg"

export const partnerImages = [
  {
    logo: dailyMail,
    link:
      "https://www.dailymail.co.uk/sciencetech/",
  },
  {
    logo: techCrunch,
    link: "https://techcrunch.com/",
  },
  {
    logo: forbes,
    link:
      "https://www.forbes.com/sites/quickerbettertech/",
  },
  { logo: mit, link: "https://www.technologyreview.com/" },
  {
    logo: techTimes,
    link:
      "https://www.techtimes.com/",
  },
  {
    logo: fattoQuotidiano,
    link:
      "https://www.ilfattoquotidiano.it/",
  },
  {
    logo: syfy,
    link:
      "https://www.syfy.com/syfywire/",
  },
  {
    logo: theScienceTimes,
    link:
      "https://science.news/",
  },
  {
    logo: miningCom,
    link:
      "https://www.mining.com/",
  },
  {
    logo: popularMachines,
    link:
      "https://www.popularmechanics.com/science/green-tech/",
  },
  {
    logo: fabcrosslogo,
    link: "https://fabcross.jp/news/",
  },
  {
    logo: telegraph,
    link:
      "https://www.telegraph.co.uk/business/2021/02/07/britains-energy-future-lies-little-box-tricks/?WT.mc_id=e_DM1334437&WT.tsrc=email&etype=Edi_",
  }
]

export const partnerImagesCarousel = [
  { logo: cambridge,
    link: "https://www.phy.cam.ac.uk/",
  },
  { logo: nvidia,
    link: "https://www.nvidia.com/en-gb/industries/energy/",
  },
  { logo: orano,
    link: "https://www.orano.group/en",
  },
  { logo: climatePledge,
    link: "https://www.theclimatepledge.com/",
  },
]
export const partnerImagesPresskit = [
  { logo: dailyMail },
  { logo: forbes },
  { logo: techCrunch },
  { logo: techTimes },
  { logo: theScienceTimes },
  { logo: telegraph },
]
